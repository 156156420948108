import React from "react"; // , { useContext }
// import { FaShareAlt } from "react-icons/fa";

// import { ClearButton } from "../../../../styles";
// import { ThemeContext } from "../../../../providers/ThemeProvider";

import { isSSR } from "../../../../api/utils";

const ShareApi = () => {
  // const { theme } = useContext(ThemeContext);
  if (!isSSR && navigator.share) {
    const click = () => {
      navigator
        .share({
          title: `${document.title}`,
          url: `${document.location.href}`,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    };
    return (
      <div>
        <button
          onClick={click}
          style={{ border: "0", background: "transparent", color: "#4285f4",cursor:"pointer" }}
        >
          <svg
            className="shake"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width="24"
            height="24"
            fill="currentColor"
          >
            <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
          </svg>
        </button>
      </div>
    );
  } else return null;
};

export default ShareApi;
