import React from "react";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import Img from "gatsby-image";

export const DynamicImg = ({ filename, alt }) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.images.edges.find((n) =>
        n.node.relativePath.includes(filename)
      );

      if (!image) return null;

      const imageFixed = image.node.childImageSharp.fluid;
      return <Img alt={alt} fluid={imageFixed} />;
    }}
  />
);

DynamicImg.propTypes = {
  filename: PropTypes.string,
  alt: PropTypes.string,
};
