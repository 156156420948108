import React from "react";
import {
  FaTwitter,
  FaFacebook,
  FaLinkedin,
  FaReddit,
  FaGooglePlus,
} from "react-icons/fa";
import { useTranslation } from "gatsby-plugin-react-i18next";

import RedirectLink from "../../common/RedirectLink";
import { Container } from "../../../styles";
import { Wrapper, Flex, Links, Details } from "./styles";

export const Footer = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";

  let myUrl =
    typeof window !== "undefined"
      ? window.location.href
      : "https://www.downloader.tube";
  let titleUrl =
    typeof window !== "undefined" ? document.title : "Download Video Online";

  return (
    <Wrapper as="footer">
      <Flex as={Container}>
        <Details direction={isRTL ? "rtl" : ""}>
          <h5>Downloader.tube</h5>
          <span className="span">
            © All rights are reserved | {new Date().getFullYear()}
            {/* | Developed By 
          <a href="#" rel="noopener noreferrer" target="_blank">
            Jamal Mdawar
          </a> */}
          </span>
          <p>
            {t("common:Notice")}{" "}
            <RedirectLink to="/terms/">
              {t("common:Terms of Service")}
            </RedirectLink>{" "}
            {t("common:Notice[2]")} {t("common:Notice[3]")}
          </p>
        </Details>
        <Links>
          {/* {social.map(({ id, name, link, icon }) => (
          <a key={id} href={link} target="_blank" rel="noopener noreferrer" aria-label={`follow me on ${name}`}>
            <img width="24" className="shake" src={icon} alt={name} />
          </a>
        ))} */}

          <a
            href={`https://twitter.com/share?url=${myUrl}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="tweet"
          >
            <FaTwitter size={32} className="shake" fill="#e94535" />
          </a>
          <a
            href={`https://www.facebook.com/share.php?u=${myUrl}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="fb"
          >
            <FaFacebook size={32} className="shake" fill="#e94535" />
          </a>
          <a
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${myUrl}&title=${titleUrl}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="linkin"
          >
            <FaLinkedin size={32} className="shake" fill="#e94535" />
          </a>
          <a
            href={`https://www.reddit.com/submit?url=${myUrl}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="reditt"
          >
            <FaReddit size={32} className="shake" fill="#e94535" />
          </a>
          <a
            href={`https://plus.google.com/share?url=${myUrl}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="googlePlus"
          >
            <FaGooglePlus size={32} className="shake" fill="#e94535" />
          </a>
        </Links>
      </Flex>
    </Wrapper>
  );
};
