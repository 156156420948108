import styled from "styled-components";

export const Select = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  margin-right: 1rem;
  position: relative;
  width: ${({ width }) => (width ? width : "100px")};
  min-width: 100px;
  overflow: hidden;
  &,
  > * {
    cursor: pointer;
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    right: 7px;
    border-width: 6px 4px;
    border-style: solid;
    width: 0;
    pointer-events: none;
  }
  &:before {
    top: 1px;
    border-color: transparent transparent #656565 transparent;
  }
  &:after {
    bottom: 1px;
    border-color: #656565 transparent transparent transparent;
  }
  select {
    text-indent: 10px;
    font-size: 14px;
    width: 100%;
    height: 30px;
    background: ${({ theme }) =>
      theme === "light" ? "transparent" : "#212121"};
    border: 0;
    outline-width: 0;
    color: ${({ theme }) => (theme === "light" ? "#000" : "#e6e6e6")};
    appearance: none;
    .activeLang {
      color: #e94535;
    }
  }
  @media (max-width: 960px) {
    margin-bottom: 1rem;
  }
`;

// export const Select = styled.div`
//   border: 1px solid #e5e5e5;
//   border-radius: 2px;
//   position: relative;
//   width: ${({ width }) => (width ? width : "100px")};
//   min-width: 100px;
//   overflow: hidden;
//   &,
//   > * {
//     cursor: pointer;
//   }
//   &:before,
//   &:after {
//     content: "";
//     position: absolute;
//     right: 7px;
//     border-width: 6px 4px;
//     border-style: solid;
//     width: 0;
//     pointer-events: none;
//   }
//   &:before {
//     top: 1px;
//     border-color: transparent transparent #656565 transparent;
//   }
//   &:after {
//     bottom: 1px;
//     border-color: #656565 transparent transparent transparent;
//   }
//   select {
//     @include box-sizing(content-box);
//     text-indent: 10px;
//     font-size: 14px;
//     width: 100%;
//     height: 30px;
//     background: ${({ theme }) =>
//       theme === "light" ? "transparent" : "#212121"};
//     border: 0;
//     outline-width: 0;
//     color: ${({ theme }) => (theme === "light" ? "#000" : "#e6e6e6")};
//     -ms-user-select: none;
//     -webkit-user-select: none;
//     -moz-user-select: -moz-none;

//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
//     .activeLang {
//       color: #e94535;
//     }
//   }
// `;
