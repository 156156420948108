import React, { useContext } from "react";
// import { navigate } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";

import i18nLanguages from "../i18n/languages";
// import languages from "../../i18n/languages";
// import PageContext from "./Layout/PageContext";
import { Select } from "../../styles";
import { ThemeContext } from "../../providers/ThemeProvider";
// import { useLocation } from "@reach/router";

const LangButton = ({ label, chosen, value }) => {
  return (
    <option value={value} className={chosen ? "activeLang" : ""}>
      {label}
    </option>
  );
};

const SelectLang = ({ languageList , selected }) => {
  // const items = languages.map((language) => ({
  //   name: i18nLanguages[language],
  //   href: originalPath,
  //   // Prop for the "Link" component  of "gatsby-plugin-react-i18next"
  //   language,
  // }));
  return (
    <>
      {languageList.map((language) => {
        return (
          <LangButton
            key={language}
            label={i18nLanguages[language]}
            value={language}
            chosen={selected === language}
          />
        );
      })}
    </>
  );
};

const Langs = () => {
  const { theme } = useContext(ThemeContext);
  const { language, languages, changeLanguage } = useI18next();
  // useEffect(() => {
  //   let lang = localStorage.getItem("gatsby-intl-language");
  //   if (lang) {
  //     const newPathname =
  //       lang === "en" ? `${originalPath}` : `/${lang}${originalPath}`;
  //     navigate(newPathname);
  //   }
  // }, []);

  // const handleSetLang = (language) => {
  //   console.log(language)
  //   const newPathname =
  //     language === "en" ? `/` : `/${language}/`;
  //   localStorage.setItem("gatsby-intl-language", language);
  //   navigate(newPathname);
  // };

  return (
    <Select theme={theme}>
      <select
        aria-label="languages"
        onChange={(e)=>changeLanguage(e.target.value)}
        // onChange={(e) => changeLocale(e.target.value)}
        // onChange={(e) => handleSetLang(e.target.value)}
        // onBlur={(e) => {
        //   handleSetLang(e.target.value);
        // }}
        defaultValue={language}
      >
        <SelectLang languageList={languages} selected={language} />
      </select>
    </Select>
  );
};

export default Langs;
