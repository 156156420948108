import React,{ useEffect, useRef } from "react";

const BannerAds = () => {
  const banner = useRef();

  const atOptions = {
    key: "1fd6b9b6b07d4dfe618e6ff34f1437d5",
    format: "iframe",
    height: 90,
    width: 728,
    params: {},
  };

//   <script type="text/javascript">
// 	atOptions = {
// 		'key' : '1fd6b9b6b07d4dfe618e6ff34f1437d5',
// 		'format' : 'iframe',
// 		'height' : 90,
// 		'width' : 728,
// 		'params' : {}
// 	};
// 	document.write('<scr' + 'ipt type="text/javascript" src="http' + (location.protocol === 'https:' ? 's' : '') + '://marineingredientinevitably.com/1fd6b9b6b07d4dfe618e6ff34f1437d5/invoke.js"></scr' + 'ipt>');
// </script>
  // process.env.NODE_ENV === "production" &&
  useEffect(() => {
    if (!banner.current.firstChild) {
      const conf = document.createElement("script");
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `//marineingredientinevitably.com/${atOptions.key}/invoke.js`;

      conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

      if (banner.current) {
        banner.current.append(conf);
        banner.current.append(script);
      }
    }
  }, []);

  return (
      <div 
      // className="flex items-center justify-center" 
      style={{display:"flex",alignItems: "center",justifyContent: "center"}}
      ref={banner} />
  );
};

export default BannerAds;
