// Match video quality, eg: 320p, 480p, 1080P, hd720
// Match numbers of 3 digits or more starting with "sh", "hd" or ending with "p"
const qualityRE = /[shd]+(\d{3,})|(\d{3,})p/i;

// Result type, "video", "playlist", "url", "url_transparent", "multi_video"
export function resultType(info) {
  if (info) {
    // Results that don't have a "_type" key are of type "video"
    return info._type ? info._type : "video";
  }
  return null;
}

// Check if the format is downloadable by the user
export function isDownloadable(format) {
  if (format) {
    // If we have already checked return the cached result
    if (Object.prototype.hasOwnProperty.call(format, "downloadable")) {
      return format.downloadable;
    }

    // Get the protocol if the format doesn't have one
    if (!format.protocol) {
      // Use "http" as the default and pass the ext for more checks
      format.protocol = getProtocol(format, "http", format.ext);
    }

    // These protocols are downloadable
    const result = ["http", "https", "ftp", "ftps"].includes(format.protocol);

    // Cache the result to prevent looping every time this function is called
    format.downloadable = result;
    return result;
  }
  return false;
}

// Check if a format is video only
export function isVideoFormat(format) {
  return format.acodec === "none";
}

// Check if a format is audio only
export function isAudioFormat(format) {
  return format.vcodec === "none";
}

// Check an extension if is an audio format
export function isAudioExt(ext) {
  // Some formats don't have "acodec" and "vcodec" so we check the ext
  // Source: https://en.wikipedia.org/wiki/Audio_file_format#List_of_formats
  return [
    "mp3",
    "m4a",
    "aac",
    "opus",
    "wav",
    "ogg",
    "oga",
    "wma",
    "weba",
    "flac",
    "aif",
    "aiff",
    "aa",
    "aax",
    "au",
    "ra",
    "ram",
  ].includes(ext);
}

// Array.sort() compare function to sort downloadable formats first
export function sortByDownloadable(a, b) {
  const ad = isDownloadable(a);
  const bd = isDownloadable(b);

  // If the first item is downloadable and the second is not
  if (ad && !bd) {
    // Treat the first as less than the second (should go before)
    return -1;
  } else {
    // Otherwise keep them in their current positions
    return 0;
  }
}

// Create a formats object sorted by the media type
// Non downloadable formats will be skipped
export function sortFormatsByType(formats, sort) {
  const sortedFormats = { normal: [], video: [], audio: [] };
  // Sort formats by the media type (normal, video, audio)
  for (let i = 0; i < formats.length; i++) {
    // Skip invalid formats (for now only 1)
    if (formats[i].ext === "mpd") {
      continue;
    }
    // Video only formats (DASH video)
    if (isVideoFormat(formats[i])) {
      sortedFormats.video.push(formats[i]);
    }
    // Audio only formats (DASH audio)
    // Check the file extension for common audio formats if "vcodec" is undefined
    else if (isAudioFormat(formats[i]) || isAudioExt(formats[i].ext)) {
      sortedFormats.audio.push(formats[i]);
    }
    // Normal video format (video + audio)
    else {
      sortedFormats.normal.push(formats[i]);
    }
  }

  // Sort formats by downloadable
  if (sort) {
    // Loop through the sortedFormats arrays and sort them
    for (let i = 0, keys = ["normal", "video", "audio"]; i < keys.length; i++) {
      sortedFormats[keys[i]].sort(sortByDownloadable);
    }
  }

  return sortedFormats;
}

// Get file extension from URL
export function getExt(url, defaultExt) {
  try {
    // Remove the hash fragment if present
    const hash = url.indexOf("#");
    if (hash !== -1) {
      url = url.substring(0, hash);
    }

    // Remove the query string if present
    const query = url.indexOf("?");
    if (query !== -1) {
      url = url.substring(0, query);
    }

    // Split on the dots and get the last item
    // Replace the character / if present (we have to use regex to replace all)
    return url
      .split(".")
      .pop()
      .replace(/\//g, "");
  } catch (e) {
    return defaultExt || "";
  }
}

export function extension(url, defaultExt) {
  if (defaultExt) {
    return defaultExt;
  } else {
    return getExt(url, defaultExt);
  }
}

// Parse a URL using the URL API or by using the DOM
export function parseURL(url) {
  try {
    // Try using the URL API if available
    const parsedURL = new URL(url);

    if (!("href" in parsedURL)) {
      throw new Error("The URL API is not fully supported");
    }

    return parsedURL;
  } catch (e) {
    // We're catching all errors even "Invalid URL"
    // Use the browser's built in parser
    const anchor = document.createElement("a");
    anchor.setAttribute("href", url);
    return anchor;
  }
}

// Get the download protocol for a format
// See: YoutubeDL.utils.determine_protocol
export function getProtocol(info, defaultProtocol, ext) {
  try {
    // If already has a protocol key return it
    if (info.protocol) {
      return info.protocol;
    }

    // Check the URL
    if (info.url.startsWith("rtmp")) {
      return "rtmp";
    } else if (info.url.startsWith("mms")) {
      return "mms";
    } else if (info.url.startsWith("rtsp")) {
      return "rtsp";
    }

    // Check the file extension if it was not passed
    ext = ext || getExt(info.url);

    if (ext === "m3u8" || ext === "f4m") {
      return ext;
    }

    // Otherwise get the URL scheme
    // Could be: http, https, ftp, ftps ...
    const parsedURL = parseURL(info.url);
    // The protocol is returned with the colon
    return parsedURL.protocol.replace(":", "");
  } catch (e) {
    return defaultProtocol || "";
  }
}

// Get the thumnbail for display
export function getThumbnail(info) {
  if (info.thumbnail) {
    return info.thumbnail;
  }
  // Some extractors have "thumbnails" array without "thumbnail"
  else if (info.thumbnails && info.thumbnails.length > 1) {
    // Return the last thumnbail which has the best quality
    return info.thumbnails[info.thumbnails.length - 1].url;
  }
  return null;
}

// Get the first format from an array of formats
export function getFormat(formatsArray, format) {
  for (let i = 0; i < formatsArray.length; i++) {
    if (formatsArray[i].ext === format) {
      return formatsArray[i];
    }
  }
  return null;
}

// Get the index of the first matching format from an array of formats
export function getFormatIndex(formatsArray, formats) {
  for (let i = 0; i < formatsArray.length; i++) {
    if (formats.includes(formatsArray[i].ext)) {
      return i;
    }
  }
  return 0;
}

// Get a object of hours, minute, seconds from a duration in seconds
export function timeObject(duration) {
  const hours = Math.floor(duration / 3600);
  // Set the variable as the remaining number of seconds
  duration %= 3600;
  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration % 60);

  return {
    hours,
    minutes,
    seconds,
  };
}

// Format seconds to HH:MM:SS string
// Source: https://stackoverflow.com/a/1322798/1209328
// Supports formatting more than 23 hours in the string
export function formatSeconds(totalSeconds) {
  if (!isNaN(totalSeconds)) {
    const time = timeObject(totalSeconds);
    // Don't show the hours part if it's 0
    const h = time.hours !== 0 ? String(time.hours) + ":" : "";
    // If hours is 0 do not pad start the minutes with 0
    const m =
      time.hours !== 0
        ? String(time.minutes).padStart(2, "0")
        : String(time.minutes);
    const s = String(time.seconds).padStart(2, "0");
    return `${h}${m}:${s}`;
  } else return false;
}

// Format bytes size
export function formatBytes(bytes, decimals) {
  if (bytes === 0) {
    return "0 Bytes";
  }

  decimals = decimals <= 0 ? 0 : decimals || 2;

  const kb = 1024;
  const i = Math.floor(Math.log(bytes) / Math.log(kb));
  const sizes = [
    "Bytes",
    "KiB",
    "MiB",
    "GiB",
    "TiB",
    "PiB",
    "EiB",
    "ZiB",
    "YiB",
  ];

  const size = parseFloat((bytes / kb ** i).toFixed(decimals));

  return `${size} ${sizes[i]}`;
}

// Format resolution (see: YoutubeDL.format_resolution)
export function formatResolution(format) {
  // some formats alread have a resolution
  if (format.resolution) {
    return format.resolution;
  }

  if (format.height) {
    if (format.width) {
      // width and height
      return format.width + "x" + format.height;
    } else {
      // height only
      return format.height + "p";
    }
  } else if (format.width) {
    // only width is available
    return format.width + "x?";
  }
}

// Get quality from width and height
// See: https://en.wikipedia.org/wiki/Graphics_display_resolution
export function qualityString(width, height) {
  if (height <= 576 && height >= 240) {
    return "SD";
  } else if (height === 720 || height === 768) {
    return "HD";
  } else if (height === 1080) {
    return "Full HD";
  } else if (width === 2048) {
    return "DCI 2K";
  } else if (width === 2560 && height === 1440) {
    return "Quad HD";
  } else if (width === 3840 && height === 2160) {
    return "4K Ultra HD";
  } else if (width === 4096) {
    return "DCI 4K";
  } else if (width === 7680) {
    return "8K Ultra HD";
  }

  return null;
}

// Get resolution type (SD, HD, Full HD, 4K, 8K)
export function getQuality(format) {
  if (!format) {
    return null;
  }

  // If we have at least height or width
  if (format.height || format.width) {
    return qualityString(format.width, format.height);
  }
  // Some formats don't have a height and width
  // But sometimes we can find the quality in format_note or format_id
  else {
    let match = null;

    // Match quality string, eg: 320p, 480P, hd720
    if (format.format_note) {
      match = format.format_note.match(qualityRE);
    }

    // If we didn't get a match from format_note, try format_id
    if (!match && format.format_id) {
      match = format.format_id.match(qualityRE);
    }

    if (match) {
      // group 1 will match "hd720", group 2 will match "320p"
      const height = match[1] || match[2];
      return qualityString(undefined, Number(height));
    }
  }

  return null;
}

// Scroll to an element on the page
export const scrollTo = (query) => {
  const el = document.querySelector(query);
  if (el) {
    try {
      // Scroll smoothly to the element
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    } catch (e) {
      // Fallback for browsers that don't support scrollIntoViewOptions
      el.scrollIntoView(true);
    }
  }
};

export const isSSR = typeof window === "undefined";
