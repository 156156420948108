import styled from "styled-components";

export const Thumbnail = styled.div`
  flex: 1;
  margin: auto;
  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
    max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "300px")};
  }

  img {
    width: 100%;
  }
`;
