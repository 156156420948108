import styled from "styled-components";

export const Card = styled.div`
  margin: 0;
  min-width: 300px;
  padding: 15px;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  background: ${({ theme }) => (theme === "light" ? "#FFF" : "#242526")};
`;
