// import React from 'react';
// import Helmet from 'react-helmet';
// import Thumbnail from 'assets/thumbnail/thumbnail.png';
// import {
//   url,
//   defaultDescription,
//   social,
//   defaultTitle,
//   socialLinks,
//   address,
//   contact,
//   legalName,
//   foundingDate,
//   logo,
// } from 'data/config';

// export const SEO = ({ title = defaultTitle, description = defaultDescription, location = '' }) => {
//   const structuredDataOrganization = `{ 
// 		"@context": "http://schema.org",
// 		"@type": "Organization",
// 		"legalName": "${legalName}",
// 		"url": "${url}",
// 		"logo": "${logo}",
// 		"foundingDate": "${foundingDate}",
// 		"founders": [{
// 			"@type": "Person",
// 			"name": "${legalName}"
// 		}],
// 		"contactPoint": [{
// 			"@type": "ContactPoint",
// 			"email": "${contact.email}",
// 			"telephone": "${contact.phone}",
// 			"contactType": "customer service"
// 		}],
// 		"address": {
// 			"@type": "PostalAddress",
// 			"addressLocality": "${address.city}",
// 			"addressRegion": "${address.region}",
// 			"addressCountry": "${address.country}",
// 			"postalCode": "${address.zipCode}"
// 		},
// 		"sameAs": [
// 			"${socialLinks.twitter}",
// 			"${socialLinks.google}",
// 			"${socialLinks.youtube}",
// 			"${socialLinks.linkedin}",
// 			"${socialLinks.instagram}",
// 			"${socialLinks.github}"
// 		]
//   	}`;

//   return (
//     <Helmet>
//       <meta name="description" content={description} />
//       <meta name="image" content={Thumbnail} />

//       <meta property="og:url" content={`${url}${location}/?ref=smakosh.com`} />
//       <meta property="og:type" content="website" />
//       <meta property="og:title" content={title} />
//       <meta property="og:description" content={description} />
//       <meta property="og:image" content={Thumbnail} />
//       <meta property="fb:app_id" content={social.facebook} />

//       <meta name="twitter:card" content="summary" />
//       <meta name="twitter:creator" content={socialLinks.twitter} />
//       <meta name="twitter:site" content={social.twitter} />
//       <meta name="twitter:title" content={title} />
//       <meta name="twitter:description" content={description} />
//       <meta name="twitter:image:src" content={Thumbnail} />
//       <script type="application/ld+json">{structuredDataOrganization}</script>
//       <link rel="publisher" href={socialLinks.google} />
//       <title>{title}</title>
//       <html lang="en" dir="ltr" />
//     </Helmet>
//   );
// };

/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
 import React from 'react';
 import PropTypes from 'prop-types';
 import Helmet from 'react-helmet';
 import { StaticQuery, graphql } from 'gatsby';
 
 export function SEO({ description, lang, meta, keywords, title }) {
   return (
     <StaticQuery
       query={detailsQuery}
       render={(data) => {
         const pageTitle = title || data.site.siteMetadata.title;
         const metaDescription = description || data.site.siteMetadata.description;
         const metaKeywords = keywords.length ? keywords : data.site.siteMetadata.keywords;
 
         return (
           <Helmet
             htmlAttributes={{
               lang,
             }}
             title={pageTitle}
             titleTemplate={`%s | ${data.site.siteMetadata.title}`}
             meta={[
               {
                 name: `description`,
                 content: metaDescription,
               },
               {
                 property: `og:title`,
                 content: pageTitle,
               },
               {
                 property: `og:description`,
                 content: metaDescription,
               },
               {
                 property: `og:type`,
                 content: `website`,
               },
               {
                 name: `twitter:card`,
                 content: `summary`,
               },
               {
                 name: `twitter:creator`,
                 content: data.site.siteMetadata.author,
               },
               {
                 name: `twitter:title`,
                 content: pageTitle,
               },
               {
                 name: `twitter:description`,
                 content: metaDescription,
               },
             ]
               .concat(
                 metaKeywords.length > 0
                   ? {
                       name: `keywords`,
                       content: metaKeywords.join(`, `),
                     }
                   : [],
               )
               .concat(meta)}
           />
         );
       }}
     />
   );
 }
 
 SEO.defaultProps = {
   title: '',
   meta: [],
   keywords: [],
   lang: `en`,
 };
 
 SEO.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.array,
   keywords: PropTypes.arrayOf(PropTypes.string),
   title: PropTypes.string,
 };
 
 const detailsQuery = graphql`
   query DefaultSEOQuery {
     site {
       siteMetadata {
         title
         description
         author
         keywords
       }
     }
   }
 `;
 