import { createGlobalStyle } from "styled-components";

export const Global = createGlobalStyle`
  html {
    font-family: 'Roboto', Helvetica, sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    body {
      margin: 0;
      padding: 0;
      font-family: 'Roboto', Helvetica, sans-serif;
      color: ${({ theme }) =>
        theme === "light" ? "hsla(0, 0%, 0%, 0.8)" : "#fff"};
      background-color: ${({ theme }) =>
        theme === "light" ? "#fff" : "#212121"};
      transition: .3s all;
      font-weight: normal;
      word-wrap: break-word;
      font-kerning: normal;
      -moz-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
      -ms-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
      -webkit-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
      font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    }
    h1,
    .h1,h2 {
      margin-bottom: 2rem;
      font-size: 26pt;
      color: ${({ theme }) => (theme === "dark" ? "#fff" : "#212121")};
  
      @media (max-width: 960px) {
        mix-blend-mode: ${({ theme }) =>
          theme === "light" ? "unset" : "difference"};
      }
    }
    a {
      color: ${({ theme }) => (theme === "light" ? "#b93529" : "#e94535")};
    }
    p {
      margin-bottom: 2.5rem;
      font-size: 17pt;
      font-weight: normal;
      line-height: 1.3;
      color: ${({ theme }) => (theme === "dark" ? "#c7c7c7" : "#707070")};
  
      @media (max-width: 960px) {
        mix-blend-mode: ${({ theme }) =>
          theme === "light" ? "unset" : "difference"};
      }
    }
  }
`;
