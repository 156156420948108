import styled from "styled-components";

export const Wrapper = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  transition: 0.3s all;
  margin: 0 0 0 1rem;
  &:focus {
    outline: none;
    transition: 0.3s all;
  }

  @media (max-width: 960px) {
    text-align: left;
    margin: 1rem 0;
  }

  img {
    margin-bottom: unset;
  }
`;
