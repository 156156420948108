import styled from "styled-components";

export const Title = styled.p`
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  direction: ${({ direction }) => (direction ? direction : "")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "2rem"};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "36pt")};
  font-weight: bold;
  @media (max-width: 680px) {
    font-size: ${({ fontMobile }) => (fontMobile ? fontMobile : "30pt")};
  }
  background-image: linear-gradient(135deg, #e94535 0%, #b93529 100%);
`;
