import styled from "styled-components";

export const ClearButton = styled.button`
  border: 0;
  cursor: pointer;
  background: transparent;
  font-size: 16pt;
  color: ${({ theme }) => (theme === "light" ? " #3f3d56" : "#e94535")};
  margin-left: 1rem;
  @media (max-width: 960px) {
    margin: 1rem 0 0;
    text-align: left;
  }
`;
