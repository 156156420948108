// import React from "react";
// import { Link } from "gatsby";
// import { injectIntl } from "react-intl";

// const RedirectLink = ({
//   to,
//   intl: { locale },
//   originalLink = false,
//   ...props
// }) => (
//   <Link
//     {...props}
//     to={locale === "en" || originalLink ? `${to}` : `/${locale}${to}`}
//   />
// );

// export default injectIntl(RedirectLink);

import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Link as I18nLink } from 'gatsby-plugin-react-i18next';

export default function RedirectLink({ to, i18n = true, ...props }) {
  if (to.startsWith('/')) {
    const Link = i18n ? I18nLink : GatsbyLink;
    return <Link to={to} {...props} />;
  }

  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a href={to} {...props} />;
}