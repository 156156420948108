const languages = {
  en: "🇬🇧 English",
  es: "🇪🇸 Español",
  de: "🇩🇪 Deutsche",
  fr: "🇹🇫 Français",
  it: "🇮🇹 Italiano",
  pt: "🇵🇹 Português",
  nb: "🇳🇴 norsk", //norway//
  el: "🇬🇷 Ελληνικά", //greek//
  cs: "🇨🇿 Czech", //Czech//
  lv: "🇱🇻 Latvietis", //latvia
  sq: "🇦🇱 Shqiptare", //albanian
  hy: "🇦🇲 հայերեն", //Armenian
  tr: "🇹🇷 Türk",
  pl: "🇵🇱 Polski",
  ru: "🇷🇺 Pусский",
  hi: "🇮🇳 हिंदी",
  ka: "🇬🇪 ქართული",
  uk: "🇺🇦 український",
  ja: "🇯🇵 日本人",
  zh: "🇨🇳 中文",
  fa: "🇮🇷 فارسی",
  ar: "🇱🇧 العربي",
};

export default languages;
