import styled from "styled-components";

export const LoadingStyle = styled.div`
  margin-right: 8px;
  border-bottom: 4px solid rgba(233, 69, 53, 0.2);
  border-left: 4px solid rgba(233, 69, 53, 0.2);
  border-right: 4px solid rgba(233, 69, 53, 0.2);
  border-top: 4px solid #e94535;
  border-radius: 100%;
  height: 18px;
  width: 18px;
  animation: rot 0.7s infinite linear;
  @keyframes rot {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
