import React, { useContext } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

import { ThemeContext } from "../../../../providers/ThemeProvider";
import ShareApi from "../ShareApi";
import ToggleTheme from "../ToggleTheme";
import { Wrapper } from "./styles";
import RedirectLink from "../../../common/RedirectLink";
import Langs from "../../../common/SelectLanguage";

const NavbarLinks = ({ desktop }) => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();

  const isLight = theme === "light";
  return (
    <Wrapper desktop={desktop} theme={theme}>
      <RedirectLink
        to="/faq/"
        style={{ color: `${isLight ? "black" : "white"}` }}
      >
        {t("common:FAQ")}
      </RedirectLink>
      <RedirectLink
        to="/terms/"
        style={{ color: `${isLight ? "black" : "white"}` }}
      >
        {t("common:Terms")}
      </RedirectLink>
      <RedirectLink
        to="/sites/"
        style={{ color: `${isLight ? "black" : "white"}` }}
      >
        {t("common:Supported Sites")}
      </RedirectLink>
      <Langs />
      <ShareApi />
      <ToggleTheme />
    </Wrapper>
  );
};

export default NavbarLinks;
