import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  margin: ${({ margin }) => (margin ? margin : "auto")};
  position: relative;
  padding: ${({ padding }) => (padding ? padding : "0.8rem 1.6rem")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "")};
  width: fit-content;
  font-weight: bold;
  line-height: 1;
  z-index: 1;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
  border-radius: 2px;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 0px;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
  }
  background-image: linear-gradient(135deg, #e94535 0%, #b93529 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(135deg, #e94535 0%, #b93529 100%);
  border-image-slice: 1;
  &:disabled,
  &[disabled] {
    cursor: wait;
  }
  @media (max-width: 37.5em) {
    background-image: none;
    border: 2px solid #b93529;
    -webkit-text-fill-color: #b93529;
  }
  &::after {
    background-image: linear-gradient(135deg, #e94535 0%, #b93529 100%);
    @media (max-width: 37.5em) {
      background-image: none;
    }
  }
  &:not([disabled]):hover {
    -webkit-text-fill-color: #fff;
    text-decoration: none;
    border-radius: 2px;
    @media (max-width: 37.5em) {
      -webkit-text-fill-color: #b93529;
    }
    &::after {
      width: 100%;
    }
    svg {
      fill: #fff;
    }
  }
  svg {
    margin-right: 8px;
  }
`;
