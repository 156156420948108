import React, { useContext } from "react";
import { ThemeContext } from "../../../providers/ThemeProvider";
import { Footer, Header } from "../../theme";
import { Global } from "./styles";
import "./style.css";
import "./fonts.css";

import BannerAds from "../../BannerAds"

import PropTypes from "prop-types";
// import { IntlProvider, addLocaleData } from "react-intl";

// import PageContext from "./PageContext";
// import { translations, languages } from "../../../i18n";

// import plData from "react-intl/locale-data/pl";
// import enData from "react-intl/locale-data/en";
// import arData from "react-intl/locale-data/ar";
// import frData from "react-intl/locale-data/fr";
// import esData from "react-intl/locale-data/es";
// import itData from "react-intl/locale-data/it";
// import deData from "react-intl/locale-data/de";
// import ruData from "react-intl/locale-data/ru";
// import ptData from "react-intl/locale-data/pt";
// import trData from "react-intl/locale-data/tr";
// import kaData from "react-intl/locale-data/ka";
// import ukData from "react-intl/locale-data/uk";
// import jaData from "react-intl/locale-data/ja";
// import hiData from "react-intl/locale-data/hi";
// import faData from "react-intl/locale-data/fa";
// import zhData from "react-intl/locale-data/zh";
// import nbData from "react-intl/locale-data/nb";
// import elData from "react-intl/locale-data/el";
// import csData from "react-intl/locale-data/cs";
// import lvData from "react-intl/locale-data/lv";
// import sqData from "react-intl/locale-data/sq";
// import hyData from "react-intl/locale-data/hy";

// addLocaleData([
//   ...plData,
//   ...enData,
//   ...arData,
//   ...frData,
//   ...esData,
//   ...itData,
//   ...deData,
//   ...ruData,
//   ...ptData,
//   ...trData,
//   ...kaData,
//   ...ukData,
//   ...jaData,
//   ...hiData,
//   ...faData,
//   ...zhData,
//   ...nbData,
//   ...elData,
//   ...csData,
//   ...lvData,
//   ...sqData,
//   ...hyData,
// ]);

const Layout = (customProps) => (PageComponent) => (props) => {
  // console.log(props)
  // const { locale } = props.pageContext;
  // const pageContextValue = { custom: customProps, page: props.pageContext };
  // const defaultLocale = languages.find((language) => language.default).locale;
  // const pageLocale = locale || defaultLocale;
  const { theme } = useContext(ThemeContext);
  // console.log(pageContextValue)
  return (
    <>
    {/* <IntlProvider locale={pageLocale} messages={translations[pageLocale]}> */}
      {/* <PageContext.Provider value={pageContextValue}> */}
        
        <Header />
        <Global theme={theme} />
        {/* <div className="container" style={{ minHeight: '80vh' }}> */}
        <BannerAds />
        <PageComponent {...props} />
        {/* </div> */}
        <Footer />

      {/* </PageContext.Provider> */}
    {/* </IntlProvider> */}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
