import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 28rem 0 4rem 0;
  background-color: #111317;
  @media (max-width: 1960px) {
    padding: 10rem 0 8rem;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 680px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`;

export const Links = styled.div`
  display: flex;
  align-items: center;

  a {
    margin: 0 0.5rem;

    img {
      margin: 0;
    }

    &:first-child,
    &:last-child {
      margin: 0;
    }
  }
`;

export const Details = styled.div`
  h5,
  .span {
    color: #ffffff;
  }
  h5 {
    font-size: 26pt;
  }
  p {
    font-size: 12pt;
    max-width: 600px;
    margin: 1rem 0 0 0;
    direction: ${({ direction }) => (direction ? direction : "")};
  }
  @media (max-width: 680px) {
    margin-bottom: 2rem;
  }
`;
